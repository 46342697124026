import React, { ReactElement, FC } from "react";
import { datadogRum } from '@datadog/browser-rum';

type successBlockProps = {
    vrgId? : string
}
const SuccessBlock: FC<any> = ({vrgId}: successBlockProps): ReactElement => {
    const environment = process.env.REACT_APP_DEPLOYMENT_ENVIRONMENT || '';
    const onRedirect = () => {
        let redirectUrl = environment === 'production' ? process.env.REACT_APP_APP_REDIRECT_URL : process.env.REACT_APP_STAGING_APP_REDIRECT_URL;
        
        if(redirectUrl) {
          redirectUrl += `?partner_id=${process.env.REACT_APP_PARTNER_ID}&rlid=${vrgId}`
          window.location.href = redirectUrl;
          datadogRum.addAction('Lead Continued Application', {});
        }
        return null;
      }

    return (
        <>
        <div className="flex flex-auto flex-col w-full lg:w-1/3 py-4 px-5 xs:mb-4 justify-center items-center bg-white mt-4 rounded-lg shadow-sm " >
          <div
            className='flex flex-col items-center w-full mx-auto'
            style={{}}
          >
            <div
              className='flex flex-col items-center w-full'
              style={{ gap: '24px', padding: '24px 0px' }}
            >
              <span className='bg-carclaritySuccess text-white text-sm font-semibold inline-flex items-center rounded-full w-24 h-24'>
                <svg
                  className='w-24 h-24'
                  viewBox='-4 -32 40 90'
                  fill='none'
                  xmlns='http://www.w3.org/2000/svg'
                >
                  <path
                    d='M12.0496 25.5903C11.6591 25.9809 11.0259 25.9809 10.6354 25.5903L1.17097 16.1259C0.78045 15.7354 0.78045 15.1022 1.17097 14.7117L4.59337 11.2893C4.98428 10.8984 5.61822 10.8988 6.00858 11.2903L10.635 15.9299C11.0255 16.3215 11.6597 16.3218 12.0506 15.9305L27.555 0.410396C27.9455 0.019535 28.579 0.0193758 28.9696 0.41004L32.3927 3.83308C32.7832 4.2236 32.7832 4.85676 32.3927 5.24729L12.0496 25.5903Z'
                    fill='white'
                  />
                </svg>
              </span>
              <div
                className='flex flex-col items-center w-full'
                style={{ gap: '16px', padding: '0px' }}
              >
                <h1
                  style={{ fontWeight: '600' }}
                  className='text-lg text-center font-avenir'
                >
                  Thank you!
                </h1>
                <p className='text-sm text-center font-light font-Montserrat'>
                  Your enquiry has been submitted. <br></br> One of our loan
                  experts will contact you shortly.
                </p>
              </div>
              {process.env.REACT_APP_TO_DISPLAY_APP === 'true' &&  
              <>
                <hr
                    style={{ backgroundColor: '#F2F2F2' }}
                    className='h-px w-full'
                  />
                <svg width="72" height="73" viewBox="0 0 72 73" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <circle cx="36" cy="36.5" r="36" fill="#E9F7F7"/>
                    <path d="M28 43H38.5V46H28V43ZM28 37H43V40H28V37ZM28 31H43V34H28V31ZM46 25H39.73C39.1 23.26 37.45 22 35.5 22C33.55 22 31.9 23.26 31.27 25H25C24.79 25 24.595 25.015 24.4 25.06C23.815 25.18 23.29 25.48 22.885 25.885C22.615 26.155 22.39 26.485 22.24 26.845C22.09 27.19 22 27.58 22 28V49C22 49.405 22.09 49.81 22.24 50.17C22.39 50.53 22.615 50.845 22.885 51.13C23.29 51.535 23.815 51.835 24.4 51.955C24.595 51.985 24.79 52 25 52H46C47.65 52 49 50.65 49 49V28C49 26.35 47.65 25 46 25ZM35.5 24.625C36.115 24.625 36.625 25.135 36.625 25.75C36.625 26.365 36.115 26.875 35.5 26.875C34.885 26.875 34.375 26.365 34.375 25.75C34.375 25.135 34.885 24.625 35.5 24.625ZM46 49H25V28H46V49Z" fill="#4AA3A2"/>
                  </svg>
                <div
                  className='flex flex-col items-center w-full'
                  style={{ gap: '16px', padding: '0px' }}
                >
                  <h1
                    style={{ fontWeight: '600' }}
                    className='text-lg text-center font-avenir'
                  >
                    Fast track your application
                  </h1>
                  <p className='text-sm text-center font-light font-Montserrat'>
                    Provide more details to help us get you on the<br></br>road faster.
                  </p>
                </div>
                <div className='flex w-full justify-center align-middle items-center'>
                    <button
                      onClick={onRedirect}
                      data-dd-action-name='Click Submit'
                      className='text-white bg-partnerBrandPrimary font-avenir text-sm px-9 py-3 rounded-full'
                      style={{ fontWeight: '500' }}
                    >
                      Continue application
                    </button>
                  </div>
              </>
              }
            </div>
          </div>
        </div>
        </>
    )
}

export default SuccessBlock