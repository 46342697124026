import React, { useState, useEffect, useRef } from 'react';
import {
  useForm,
  SubmitHandler,
  FormProvider,
} from 'react-hook-form';
import { ErrorMessage } from '@hookform/error-message';
import { RouletteSpinnerOverlay } from 'react-spinner-overlay';
import { datadogRum } from '@datadog/browser-rum';
import '../styles/expressFormDynamic.css';
import SuccessBlock from './successBlock';


const BUSINESS_STRUCTURE_COMPANY = 'Company'
const BUSINESS_STRUCTURE_SOLE_TRADER = 'Sole Trader'
const BUSINESS_STRUCTURE_PARTNERSHIP = 'Partnership'
const BUSINESS_STRUCTURE_TRUST = 'Trust'
const EMPLOYMENT_TYPE_BUSINESS_OWNER = 'Business Owner Or Self Employed'
const EMPLOYMENT_TYPE_EMPLOYEE = 'Employee'

enum States {
  NSW = 'NSW',
  VIC = 'VIC',
  QLD = 'QLD',
  SA = 'SA',
  WA = 'WA',
  TAS = 'TAS',
  NT = 'NT',
  ACT = 'ACT',
}

enum EmploymentStatus {
  FullTime = 'Full Time',
  PartTime = 'Part Time',
  Casual = 'Casual',
  Contract = 'Contract',
  SelfEmployed = 'Self Employed',
  Unemployed = 'Unemployed',
}

enum EmploymentType {
  Employee = 'Employee',
  BusinessOwner = EMPLOYMENT_TYPE_BUSINESS_OWNER,
  Unemployed = EMPLOYMENT_TYPE_EMPLOYEE,
}

enum BusinessStructure {
  SoleTrader = BUSINESS_STRUCTURE_SOLE_TRADER,
  Partnership = BUSINESS_STRUCTURE_PARTNERSHIP,
  Company = BUSINESS_STRUCTURE_COMPANY,
  Trust = BUSINESS_STRUCTURE_TRUST,
}

enum LoanPurpose {
  CarLoan = 'Car Loan',
  Motorcycle = 'Motorcycle Loan',
  Caravan = 'Caravan Loan',
  Boat = 'Boat Loan',
  PersonalLoanSec = 'Personal Loan (Secured)',
  PersonalLoanUnsec = 'Personal Loan (Unsecured)',
  HorseFloat = 'Horse Float Loan',
  Tractor = 'Tractor Loan',
  OtherAsset = 'Other Asset (Tractor, Trailer etc.)',
}

type Inputs = {
  firstName: string;
  lastName: string;
  gender: string;
  dateOfBirth: string;
  age: number;
  driversLicenceNumber: string;
  licenceIssuingState: States;
  phoneNumber: string;
  email: string;
  livingSituation: string;
  residentialStatus: string;
  address: string;
  atAddressYears: number | string;
  atAddressMonths: number;
  postcode: string;
  suburb: string;
  state: States;
  timeAtAddressYears: number;
  timeAtAddressMonths: number;
  employmentStatus: EmploymentStatus;
  employmentType: EmploymentType;
  occupation: string;
  employerName: string;
  atJobYears: number;
  atJobMonths: number;
  incomeFrequency: string;
  incomeAfterTax: number;
  afterTaxSalaryYearly: number;
  loanPurpose: LoanPurpose;
  loanAmount: number;
  loanTermMonths: number;
  detailsOfEnquiry: string;
  acceptTerms: boolean;
  acceptTermsPartner: boolean;
  acceptPicklesTerms: boolean;
  currentlyLiving: boolean;
  currentlyWorking: boolean;
  //TBD that if we have business data as new payload do we need businessName else deprecate
  businessName: string;
  businessStructure: BusinessStructure;
  abnRegistrationPeriod: string;
  registeredGST: boolean;
  utmSource: string;
  utmMedium: string;
  utmCampaign: string;
  utmContent: string;
};

declare const window: Window & { dataLayer: Record<string, unknown>[] };

type expressFormProps = {
  isMobile ?: boolean
  onsubmitHook : (data:any) => void
}

const ExpressForm = (props: expressFormProps) => {
  const [, setHasClickedSubmit] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [formSubmitted, setFormSubmitted] = useState<boolean>(false);
  const [formSubmittedError, setFormSubmittedError] = useState<boolean>(false);
  const [apiErrorMessage, setApiErrorMessage] = useState<string>('');
  const [vrgId, setVrgId] = useState<string>('');
  const [assetInfo, setAssetInfo] = useState<object>({});
  const environment = process.env.REACT_APP_DEPLOYMENT_ENVIRONMENT || '';
  const apiEndpoint = environment === 'production' ? process.env.REACT_APP_API_URL || '' : process.env.REACT_APP_STAGING_API_URL || '';
  const apiKey = environment === 'production' ? process.env.REACT_APP_API_KEY || '' : process.env.REACT_APP_STAGING_API_KEY || '';
  const {
    register,
    handleSubmit,
    setFocus,
    setValue,
    formState: { errors },
  } = useForm<Inputs>({ defaultValues: { acceptTerms: false } });
  const methods = useForm<Inputs>();
  // ** Personal Watchers
  const [firstName] = useState("");
  const [lastName] = useState("")
  const [email] = useState("");
  const [phoneNumber] = useState("");
  const [loanAmount] = useState("");
  //changed the name as sstate because state suffix is internal
  const [sstate] = useState("");
  const [businessName] = useState("");
  const [businessStructure] = useState("");
  const partnerBrandPrimary = process.env.REACT_APP_PARTNER_COLOUR_PRIMARY;
  // const partnerBrandSecondary = process.env.REACT_APP_PARTNER_COLOUR_SECONDARY;
  const partnerBrandError = process.env.REACT_APP_PARTNER_COLOUR_ERROR;
  const showBusinessInputs = process.env.REACT_APP_SHOW_BUSINESS_INPUTS === 'true';
  const effectRan = useRef(false);

  // ** Employment Watchers

  function isTouched(inputField: any) {
    return Boolean(inputField);
  }

  useEffect(() => {
    if (formSubmittedError) {
      window.scrollTo(0, 0);
      setTimeout(() => {
        setFormSubmittedError(false);
      }, 5000);
    }

    if (Object.keys(errors).length > 0) {
      const firstError = Object.keys(errors)[0] as keyof typeof errors;
      console.log(firstError as string);
      setFocus(firstError as any);
    }
    const searchParams = new URLSearchParams(window.location.search);
    const qspBusinessName = searchParams.get('businessname');
    const qspFirstName = searchParams.get('firstname');
    const qspLastName = searchParams.get('lastname');
    const qspEmail = searchParams.get('emailaddress');
    const qspPhone = searchParams.get('phoneno');
    const qspLoanAmount = searchParams.get('loanamount');
    const utm_source = searchParams.get('utm_source');
    const utm_medium = searchParams.get('utm_medium');
    const utm_campaign = searchParams.get('utm_campaign');
    const utm_content = searchParams.get('utm_content');

    if (qspBusinessName) {
      setValue('businessName', qspBusinessName);
    }
    if (qspFirstName) {
      setValue('firstName', qspFirstName);
    }
    if (qspLastName) {
      setValue('lastName', qspLastName);
    }
    if (qspEmail) {
      setValue('email', qspEmail);
    }
    if (qspPhone) {
      setValue('phoneNumber', qspPhone);
    }
    if (qspLoanAmount) {
      setValue('loanAmount', Number(qspLoanAmount));
    }
    if (utm_source) {
      setValue('utmSource', utm_source);
    }
    if (utm_medium) {
      setValue('utmMedium', utm_medium);
    }
    if (utm_campaign) {
      setValue('utmCampaign', utm_campaign);
    }
    if (utm_content) {
      setValue('utmContent', utm_content);
    }

    // businessname=*|BUSINESS_N|*&firstname=*|FNAME|*&lastname= *|LNAME|*&emailaddress=*|EMAIL|*&phoneno=*|PER_PHONE|*
  }, [errors, setFocus, formSubmittedError, setValue, environment, setAssetInfo, setIsLoading]);

  useEffect(()=>{
    if (effectRan.current) return;
    const searchParams = new URLSearchParams(window.location.search);
    const stockId = searchParams.get(process.env.REACT_APP_PARTNER_ITEM_ID_NAME as string)
    if(process.env.REACT_APP_VEHICLE_FETCHING_ENABLED === 'true' && stockId) {
      let envVar:{[key:string]: any} = {};
      try {
        envVar = environment === 'production' ? JSON.parse(process.env.REACT_APP_VEHICLE_FETCHING_METADATA as string) : JSON.parse(process.env.REACT_APP_STAGING_VEHICLE_FETCHING_METADATA as string)
      } catch (error) {
        return;
      }
      setIsLoading(true);
      fetch(`${envVar.url}/${stockId}`,{
        method: envVar.method,
      })
      .then((response)=> response.json())
      .then((data)=>{
        if(data) {
          const condition = retrieveAssetVar(envVar.condition, data);
          const use = retrieveAssetVar(envVar.use, data);
          const vIn = retrieveAssetVar(envVar.vIn, data);
          const year = retrieveAssetVar(envVar.buildYear, data);
          const description = retrieveAssetVar(envVar.description, data);
          const loanAmount = retrieveAssetVar(envVar.loanAmount, data);
          const assetInfo: {
            condition?: string,
            use?: string,
            vIN?: string,
            buildYear?: number,
            description?: string,
          } = {};
          if(condition !== undefined) assetInfo.condition = condition;
          if(use !== undefined) assetInfo.use = use;
          if(vIn !== undefined) assetInfo.vIN = vIn;
          if(year !== undefined) assetInfo.buildYear = parseInt(year);
          if(description !== undefined) assetInfo.description = description;
          setAssetInfo(assetInfo)
          setValue('loanAmount', loanAmount === undefined ? '': loanAmount)
        }
      }).catch(e=>{
        console.error(e);
      }).finally(()=>setIsLoading(false))
      effectRan.current = true;
    }
  }, [environment, setValue, setIsLoading]);

  const retrieveAssetVar = (envVar: any, data: any)=>{
    if(envVar?.type === 'static') {
      return envVar?.value;
    } else if(envVar?.type === 'dynamic') {
      let res = envVar?.template;
      if(typeof res === 'string') {
        for(const p in envVar?.placeholders) {
          const keys = p.split('.');
          let cur = data;
          for(const k of keys) {
            cur = cur[k]
            if(cur === undefined) {
              return undefined
            }
          }
          res = res.replaceAll(envVar.placeholders[p], cur)
        }
    
        return res;
      } else {
        return undefined
      }
    }
    return undefined
  } 

  const onError = (errors: any, e: any) => {
    setHasClickedSubmit(true);
  };

  const onSubmit: SubmitHandler<Inputs> = (data) => {
    setHasClickedSubmit(true);
    datadogRum.addAction('Form Submitted');
    window.dataLayer.push({
      event: 'formSubmitted',
    });
    // ** change phone numbers prefixed with +61 or 61 to 0
    if (data.phoneNumber.startsWith('61')) {
      data.phoneNumber = data.phoneNumber.replace('61', '0');
    }
    if (data.phoneNumber.startsWith('+61')) {
      data.phoneNumber = data.phoneNumber.replace('+61', '0');
    }

    datadogRum.setUser({
      name: `${data.firstName} ${data.lastName}`,
      email: data.email,
      phone: data.phoneNumber,
    });
    const securedFinance = process.env.REACT_APP_CC_IS_SECURED_FINANCE === 'false' ? false : true 
    const turboReferralBody = {
      firstName: data.firstName,
      lastName: data.lastName,
      mobile: data.phoneNumber,
      email: data.email,
      urgency: 'ASAP',
      securedFinance,
      saleType: 'Dealer',
      purchasePrice: data.loanAmount || 0,
      certaintyStage: 'Exact',
      requestedLoanTermYears: 5,
      currentWorkStatus: EMPLOYMENT_TYPE_EMPLOYEE,
      residency: {
        state: data.state,
      },
      asset: {
        use: process.env.REACT_APP_CC_IS_ASSET_FINANCE === 'true' ? "Business" : "Personal",
        ...assetInfo
      },
      partnerLeadId: data.phoneNumber || 'No Lead ID',
      additionalInformation: data.businessName
        ? `Business Name: ${data.businessName}`
        : null,
      lastClickUTMSource: data.utmSource || null,
      lastClickUTMMedium: data.utmMedium || null,
      lastClickUTMCampaign: data.utmCampaign || null,
      lastClickUTMContent: data.utmContent || null,
      business : {}
    };
    /**
     * business fields 
     */
    if(showBusinessInputs){
      turboReferralBody.business = {
        businessName : data.businessName,
        businessStructure : data.businessStructure
      }
      turboReferralBody.currentWorkStatus = EMPLOYMENT_TYPE_BUSINESS_OWNER
    }
    setIsLoading(true);
    fetch(`${apiEndpoint}`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'X-PARTNER-ID': process.env.REACT_APP_PARTNER_ID || '',
        'X-API-KEY': apiKey,
      },
      body: JSON.stringify(turboReferralBody),
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.error) {
          // ** Send error to GTM dataLayer

          window.dataLayer.push({
            event: 'formSubmitError',
            error: data.error,
          });
          // ** Send error to datadog RUM
          datadogRum.addError(data.error);
          setIsLoading(false);
          setApiErrorMessage(data.error);
          setFormSubmittedError(true);
        } else {
          // ** add created lead data to Datadog
          datadogRum.addAction('Lead Submit Success', { data });
          datadogRum.stopSession()
          // ** Send success to GTM dataLayer
          window.dataLayer.push({
            event: 'formSuccessfullySubmitted',
          });
          setFormSubmitted(true);
          setIsLoading(false);
          setVrgId(data.id)
          props.onsubmitHook({vrgId: data.id})
        }
      })
      .catch((error) => {
        setIsLoading(false);
        // ** Send error to GTM dataLayer
        window.dataLayer.push({
          event: 'formSubmitError',
          error: error,
        });
        // ** Send error to datadog RUM
        datadogRum.addError(error);
        setApiErrorMessage(error.toString());
        setFormSubmittedError(true);
      });
  };

  // const sectionTitle = {
  //   fontSize: '14px',
  //   fontWeight: '600',
  //   lineHeight: '19px',
  //   color: '#303030',
  // };
  // const label = {
  //   fontSize: '12px',
  //   fontWeight: '700',
  //   lineHeight: '16px',
  //   color: '#303030',
  // };

  const inputNotTouched = {
    fontSize: '14px',
    borderColor: '#E8E8E8',
  };

  const inputTouched = {
    fontSize: '14px',
    borderColor: partnerBrandPrimary,
  };

  const inputError = {
    fontSize: '14px',
    borderColor: partnerBrandError,
  };

  // const selectInputNotTouched = {
  //   fontSize: '14px',
  //   borderColor: '#E8E8E8',
  //   backgroundImage: `url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 20 20'%3E%3Cpath stroke='%23004E93' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M6 8l4 4 4-4'/%3E%3C/svg%3E")`,
  //   backgroundPosition: `right 15px center`,
  //   backgroundRepeat: `no-repeat`,
  //   backgroundSize: `25px`,
  //   paddingRight: `15px`,
  //   alignItems: 'center',
  // };

  // const selectInputTouched = {
  //   fontSize: '14px',
  //   borderColor: partnerBrandPrimary,
  //   backgroundImage: `url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 20 20'%3E%3Cpath stroke='%23004E93' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M6 8l4 4 4-4'/%3E%3C/svg%3E")`,
  //   backgroundPosition: `right 15px center`,
  //   backgroundRepeat: `no-repeat`,
  //   backgroundSize: `25px`,
  //   paddingRight: `15px`,
  //   alignItems: 'center',
  // };

  // const selectInputError = {
  //   fontSize: '14px',
  //   borderColor: partnerBrandError,
  //   backgroundImage: `url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 20 20'%3E%3Cpath stroke='%23004E93' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M6 8l4 4 4-4'/%3E%3C/svg%3E")`,
  //   backgroundPosition: `right 15px center`,
  //   backgroundRepeat: `no-repeat`,
  //   backgroundSize: `25px`,
  //   paddingRight: `15px`,
  //   alignItems: 'center',
  // };

  const errorText = {
    fontWeight: '400',
    fontFamily: 'Avenir, sans-serif',
    fontSize: '12px',
    lineHeight: '16px',
    color: partnerBrandError,
  };
  const checkboxErrorText = {
    fontWeight: '400',
    fontFamily: 'Avenir, sans-serif',
    fontSize: '12px',
    lineHeight: '16px',
    color: partnerBrandError,
    paddingTop: '8px',
    marginTop: '8px',
  };

  function showApiErrorToast() {
    return (
      <>
        <div className='fixed flex justify-center items-center w-full p-4 top-5'>
          <div className='max-w-xs bg-white border-2 border-partnerBrandError rounded-md shadow-lg'>
            <div className='flex p-4'>
              <div className='ml-3'>
                <p className='text-sm text-gray-700'>{apiErrorMessage}</p>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }

  function formSubText() {
    if (process.env.REACT_APP_PARTNER_FORM_SUBTEXT !== '') {
      return (
        <h3 className='text-sm font-light text-center px-6 pb-6'>
          {process.env.REACT_APP_PARTNER_FORM_SUBTEXT}
        </h3>
      );
    }
  }

  if (!formSubmitted) {
    datadogRum.startView({
      name: 'Express Form',
    });
    return (
      <>
        <div className="flex flex-auto flex-col xs:w-full lg:w-1/3 mx-6 md:ml-3 md:mr-6 py-4 px-5 xs:mb-4 justify-center items-center bg-white mt-4 rounded-lg shadow-sm " >
          {isLoading ? (
            <RouletteSpinnerOverlay
              loading={isLoading}
              color={partnerBrandPrimary}
            />
          ) : null}
          {/* <div className="flex flex-col items-center mx-auto" style={{ gap: "24px", padding: "48px 20px"}}>
              <div className="flex flex-col items-center w-full" style={{gap: "8px"}}>
                  <h1 style={{fontWeight: "600", fontSize: "20px", lineHeight: "27px"}} className="text-center font-avenir">Express Application</h1>
              </div>

          </div> */}

          <FormProvider {...methods}>
            <form onSubmit={handleSubmit(onSubmit)}>
              {/* <div className="flex flex-col justify-center max-w-md lg:w-1/3 w-full px-5 lg:px-0 mx-auto" style={{ gap: "24px"}}> */}
              <div className='flex flex-col  justify-center' style={{}}>
                <h1
                  style={{}}
                  className='text-center font-avenir font-medium md:font-semibold text-xl pb-2'
                >
                  {process.env.REACT_APP_PARTNER_FORM_TEXT}
                </h1>
                {formSubText()}
                <div
                  id='formStart'
                  className='flex flex-col w-full items-start gap-4 p-0'
                >
                  {/* <h2 style={sectionTitle} className="font-avenir">Personal details</h2> */}
                  <div
                    className='flex flex-col w-full items-start'
                    style={{ gap: '8px', padding: '0' }}
                  >
                    <label className='block font-light text-xs' htmlFor='firstName'>
                      First name
                    </label>
                    <input
                      id='firstName'
                      maxLength={20}
                      style={
                        isTouched(firstName)
                          ? inputTouched
                          : errors?.firstName?.message
                            ? inputError
                            : inputNotTouched
                      }
                      className='text-center block w-full bg-transparent border-1 rounded-full without-ring'
                      type='text'
                      placeholder=''
                      {...register('firstName', {
                        required: 'Please enter your first name',
                      })}
                    />
                    <ErrorMessage
                      errors={errors}
                      name='firstName'
                      render={({ message }) => <p style={errorText}>{message}</p>}
                    />
                  </div>

                  <div
                    className='flex flex-col w-full items-start'
                    style={{ gap: '8px', padding: '0' }}
                  >
                    <label className='block font-light text-xs' htmlFor='lastName'>
                      Last name
                    </label>
                    <input
                      id='lastName'
                      maxLength={20}
                      style={
                        isTouched(lastName)
                          ? inputTouched
                          : errors?.lastName?.message
                            ? inputError
                            : inputNotTouched
                      }
                      className='text-center block w-full bg-transparent border-1 rounded-full without-ring'
                      type='text'
                      placeholder=''
                      {...register('lastName', {
                        required: 'Please enter your last name',
                      })}
                    />
                    <ErrorMessage
                      errors={errors}
                      name='lastName'
                      render={({ message }) => <p style={errorText}>{message}</p>}
                    />
                  </div>

                  <div
                    className='flex flex-col w-full items-start'
                    style={{ gap: '8px', padding: '0' }}
                  >
                    <label className='block font-light text-xs' htmlFor='email'>
                      Email
                    </label>
                    <input
                      id='email'
                      style={
                        isTouched(email)
                          ? inputTouched
                          : errors?.email?.message
                            ? inputError
                            : inputNotTouched
                      }
                      className='text-center block w-full bg-transparent border-1 rounded-full without-ring placeholder-gray-300'
                      type='email'
                      placeholder='yourname@domain.com'
                      {...register('email', {
                        required: 'Email is required',
                        pattern: {
                          value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                          message:
                            'That doesn’t look right. Please enter your email address in the correct format.',
                        },
                      })}
                    />
                    <ErrorMessage
                      errors={errors}
                      name='email'
                      render={({ message }) => <p style={errorText}>{message}</p>}
                    />
                  </div>

                  <div
                    className='flex flex-col w-full items-start'
                    style={{ gap: '8px', padding: '0' }}
                  >
                    <label className='block font-light text-xs' htmlFor='phoneNumber'>
                      Mobile
                    </label>
                    <input
                      id='phoneNumber'
                      // pattern='^04[0-9]{8}'
                      pattern='^(61)?4[0-9]{9}'
                      maxLength={11}
                      onWheel={(e) => (e.target as HTMLElement).blur()}
                      inputMode='numeric'
                      style={
                        isTouched(phoneNumber)
                          ? inputTouched
                          : errors?.phoneNumber?.message
                            ? inputError
                            : inputNotTouched
                      }
                      className='text-center block w-full bg-transparent border-1 rounded-full without-ring placeholder-gray-300 '
                      type='tel'
                      placeholder='04xx xxx xxx'
                      onKeyDown={(event) => {
                        if (
                          !/[0-9]|ArrowLeft|ArrowRight|Backspace|Delete|Tab/.test(
                            event.key
                          )
                        ) {
                          event.preventDefault();
                        }
                      }}
                      {...register('phoneNumber', {
                        required: 'Please enter your Australian mobile number',
                        maxLength: 11,
                        pattern: {
                          // value: /^04[0-9]{8}/,
                          value: /^((\+61|61|0)[4])([ -]?[0-9]){8}$/,
                          message:
                            'Entered Australian mobile number format is incorrect. Re-enter it and try again',
                        },
                      })}
                    />
                    <ErrorMessage
                      errors={errors}
                      name='phoneNumber'
                      render={({ message }) => <p style={errorText}>{message}</p>}
                    />
                  </div>

                  <div className="flex flex-col w-full items-start" style={{ gap: "8px", padding:"0"}}>
                      <label style={{fontSize: "12px", fontWeight: "500", color: "#303030"}}className="block" htmlFor="state">State</label>
                      <select required defaultValue={''} id="state" style={isTouched(sstate)? inputTouched : errors?.state?.message ? inputError : inputNotTouched} className="text-center block w-full bg-transparent border-1 rounded-full without-ring " placeholder="Business Structure" {...register("state", { required: "Please select your state"})} >
                          <option hidden disabled value=''>Select</option>
                          <option value="NSW">NSW</option>
                          <option value="VIC">VIC</option>
                          <option value="QLD">QLD</option>
                          <option value="WA">WA</option>
                          <option value="SA">SA</option>
                          <option value="TAS">TAS</option>
                          <option value="ACT">ACT</option>
                          <option value="NT">NT</option>
                      </select>
                      <ErrorMessage errors={errors} name="state" render={({ message }) => <p style={errorText}>{message}</p>} />
                  </div>
                  {showBusinessInputs ? <div
                    className='flex flex-col w-full items-start'
                    style={{ gap: '8px', padding: '0' }}
                  >
                    <label className='block font-light text-xs' htmlFor='businessName'>
                      Business name
                    </label>
                    <input
                      id='businessName'
                      style={
                        isTouched(businessName)
                          ? inputTouched
                          : errors?.businessName?.message
                            ? inputError
                            : inputNotTouched
                      }
                      className='text-center block w-full bg-transparent border-1 rounded-full without-ring placeholder-gray-300'
                      type='text'
                      placeholder=''
                      {...register('businessName', {
                        required: 'Business Name is required',
                      })}
                    />
                    <ErrorMessage
                      errors={errors}
                      name='businessName'
                      render={({ message }) => <p style={errorText}>{message}</p>}
                    />
                  </div> : null}
                  {showBusinessInputs ? <div className="flex flex-col w-full items-start" style={{ gap: "8px", padding:"0"}}>
                      <label style={{fontSize: "12px", fontWeight: "500", color: "#303030"}}className="block" htmlFor="businessStructure">Business structure</label>
                      <select required defaultValue={''} id="state" style={isTouched(businessStructure)? inputTouched : errors?.businessStructure?.message ? inputError : inputNotTouched} className="text-center block w-full bg-transparent border-1 rounded-full without-ring " placeholder="Business Structure" {...register("businessStructure", { required: "Please select your Business structure"})} >
                          <option hidden disabled value=''>Select</option>
                          <option value={BUSINESS_STRUCTURE_COMPANY}>{BUSINESS_STRUCTURE_COMPANY}</option>
                          <option value={BUSINESS_STRUCTURE_SOLE_TRADER}>{BUSINESS_STRUCTURE_SOLE_TRADER}</option>
                          <option value={BUSINESS_STRUCTURE_TRUST}>{BUSINESS_STRUCTURE_TRUST}</option>
                          <option value={BUSINESS_STRUCTURE_PARTNERSHIP}>{BUSINESS_STRUCTURE_PARTNERSHIP}</option>
                      </select>
                      <ErrorMessage errors={errors} name="state" render={({ message }) => <p style={errorText}>{message}</p>} />
                  </div> : null}

                  <div
                    className='flex flex-col w-full items-start'
                    style={{ gap: '24px', padding: '0' }}
                  >
                    {/* <h2 style={sectionTitle} className="font-avenir">Loan details</h2> */}
                    <div
                      className='flex flex-col w-full items-start'
                      style={{ gap: '8px', padding: '0' }}
                    >
                      <label className='block font-light text-xs' htmlFor='loanAmount'>
                        Loan amount <p style={{ display: 'inline' }} className='text-gray-300 font-light'> (Optional)</p>
                      </label>
                      <div className='relative w-full'>
                        <span className='absolute left-4 top-1/2 transform -translate-y-1/2'>
                          $
                        </span>
                        <input
                          id='loanAmount'
                          step={1}
                          maxLength={7}
                          onWheel={(e) => (e.target as HTMLElement).blur()}
                          onInput={(e) => {
                            const input = e.target as HTMLInputElement;
                            if (input.value.length > 7) {
                              input.value = input.value.slice(0, 7);
                            }
                          }}
                          onKeyDown={(event) => {
                            if (
                              !/[0-9]|ArrowLeft|ArrowRight|Backspace|Delete|Tab/.test(
                                event.key
                              )
                            ) {
                              event.preventDefault();
                            }
                          }}
                          inputMode='numeric'
                          style={
                            isTouched(loanAmount)
                              ? inputTouched
                              : errors?.loanAmount?.message
                                ? inputError
                                : inputNotTouched
                          }
                          className='text-center block w-full bg-transparent border-1 rounded-full without-ring placeholder-gray-300'
                          type='number'
                          placeholder='Amount'
                          {...register('loanAmount', { maxLength: 7 })}
                        />
                        <ErrorMessage
                          errors={errors}
                          name='loanAmount'
                          render={({ message }) => (
                            <p style={errorText}>{message}</p>
                          )}
                        />
                      </div>
                    </div>
                  </div>

                  <hr
                    style={{ backgroundColor: '#F2F2F2' }}
                    className='h-px w-full'
                  />
                </div>

                <div className='flex flex-col w-full pt-4 gap-4'>
                  {process.env.REACT_APP_PARTNER_PRIVACY_URL && 
                    <div className="">
                      <div className='flex w-full'>
                        <input
                          type='checkbox'
                          className='w-5 h-5 text-partnerBrandPrimary border-partnerBrandPrimary border-2 mr-2 rounded without-ring'
                          placeholder='acceptTermsPartner'
                          {...register('acceptTermsPartner', {
                            required:
                              `To continue you need to agree with the ${process.env.REACT_APP_PARTNER_NAME} privacy policy`,
                          })}
                        />
                        <p
                          className='pl-2 font-avenir'
                          style={{ fontSize: '12px', color: '#303030' }}
                        >
                          I confirm that I have read and accepted {process.env.REACT_APP_PARTNER_NAME}{' '}
                          <a
                            target='_blank'
                            href={process.env.REACT_APP_PARTNER_PRIVACY_URL}
                            style={{
                              textDecoration: 'underline',
                              color: partnerBrandPrimary,
                            }}
                            rel='noreferrer'
                          >
                            privacy policy
                          </a>
                          .{' '}
                        </p>
                      </div>
                      <ErrorMessage
                        errors={errors}
                        name='acceptTermsPartner'
                        render={({ message }) => (
                          <p style={checkboxErrorText}>{message}</p>
                        )}
                      />
                    </div>
                  }
                  <div className='flex w-full'>
                    <input
                      type='checkbox'
                      className='w-5 h-5 text-partnerBrandPrimary border-partnerBrandPrimary border-2 mr-2 rounded without-ring'
                      placeholder='acceptTerms'
                      {...register('acceptTerms', {
                        required:
                          'To continue you need to agree with the CarClarity privacy policy, credit guide, privacy consent and electronic document delivery consent',
                      })}
                    />
                    <p
                      className='pl-2 font-avenir'
                      style={{ fontSize: '12px', color: '#303030' }}
                    >
                      I confirm that I have read and accepted CarClarity{' '}
                      <a
                        target='_blank'
                        href='https://www.carclarity.com.au/privacy-policy'
                        style={{
                          textDecoration: 'underline',
                          color: partnerBrandPrimary,
                        }}
                        rel='noreferrer'
                      >
                        privacy policy
                      </a>
                      ,{' '}
                      <a
                        target='_blank'
                        href='https://carclarity.com.au/credit-guide'
                        style={{
                          textDecoration: 'underline',
                          color: partnerBrandPrimary,
                        }}
                        rel='noreferrer'
                      >
                        credit guide
                      </a>
                      ,{' '}
                      <a
                        target='_blank'
                        href='https://carclarity.com.au/privacy-act-consent-electronic-delivery'
                        style={{
                          textDecoration: 'underline',
                          color: partnerBrandPrimary,
                        }}
                        rel='noreferrer'
                      >
                        privacy consent
                      </a>{' '}
                      and{' '}
                      <a
                        target='_blank'
                        href='https://carclarity.com.au/electronic-document-delivery-consent'
                        style={{
                          textDecoration: 'underline',
                          color: partnerBrandPrimary,
                        }}
                        rel='noreferrer'
                      >
                        electronic document delivery consent
                      </a>
                    </p>
                  </div>
                  <ErrorMessage
                    errors={errors}
                    name='acceptTerms'
                    render={({ message }) => (
                      <p style={checkboxErrorText}>{message}</p>
                    )}
                  />
                  <div className='flex w-full justify-center align-middle items-center'>
                    <button
                      onClick={handleSubmit(onSubmit, onError)}
                      data-dd-action-name='Click Submit'
                      className='text-white bg-partnerBrandPrimary font-avenir text-sm px-9 py-3 rounded-full'
                      style={{ fontWeight: '500' }}
                    >
                      Submit
                    </button>
                  </div>
                  <div className='flex w-full'>
                    <p
                      className='pl-2 font-avenir'
                      style={{ fontSize: '10px', color: '#BDBDBD' }}
                    >
                      *Amount funded determined by your financial circumstances; your broker will discuss your options with you. Rates are subject to change.
                    </p>
                  </div>
                </div>

              </div>
              <div className='flex flex-col items-center gap-1 self-stretch mt-4 '>
                <div className='self-stretch'>
                  <p style={{ fontFamily: 'Avenir', fontSize: '10px', fontWeight: '800', fontStyle: 'normal', lineHeight: 'normal', color: '#BDBDBD' }}>{process.env.REACT_APP_PARTNER_DISCLAIMER_TITLE}</p>
                </div>
                <div className='self-stretch'>
                  <p style={{ fontFamily: 'Avenir', fontSize: '10px', fontWeight: '350', fontStyle: 'normal', lineHeight: 'normal', color: '#BDBDBD' }}>{process.env.REACT_APP_PARTNER_DISCLAIMER_BODY}</p>
                </div>
              </div>

            </form>
          </FormProvider>

          {formSubmittedError ? showApiErrorToast() : null}
        </div>
      </>
    );
  } else {
    // window.scrollTo(0, 0);
    datadogRum.startView({
      name: 'Express Form - Submitted',
    });
    if(props.isMobile){
      return
    }
    return (
      <>
        <SuccessBlock vrgId={vrgId} />
      </>
    );
  }
};

export default ExpressForm;
